<template>
  <div
    :class="[
      'items-self justify-center text-gray-800 flex flex-wrap organism product-grid gap-8',
      processingQueue ? 'cursor-wait cursor-wait-override' : '',
    ]"
  >
    <div
      v-if="
        isLoggedIn && route.name === 'my-favorites' && gridItems.length === 0
      "
      class="mx-8 text-xl text-center text-gray-700 xl:mx-auto"
    >
      <p>
        You have not saved any Favorites yet.
        <NuxtLink
          to="/new-home-search/"
          class="font-semibold text-fr-secondary-dark whitespace-nowrap"
          >Click here to see our available floorplans.</NuxtLink
        >
      </p>
      <ClientOnly>
        <p class="py-12 text-base">
          Or if you have added floorplans and they aren't showing up yet,
          <button
            type="button"
            @click="hardRefresh"
            class="font-semibold text-fr-secondary-dark whitespace-nowrap"
          >
            click here to refresh the page.
          </button>
        </p>
      </ClientOnly>
    </div>
    <div
      v-for="item in gridItems"
      class="z-0 w-full max-w-sm mb-2 text-center item"
      :data-base-price="item.basePrice"
      :data-collection="item.collection"
      :data-type="item.type"
      :data-location="item.location"
      :data-id="item.id"
      :key="`${item.id}-${gridKey}-${item.link}`"
    >
      <ClientOnly>
        <NuxtLink :to="item.link">
          <h3 class="pb-2 text-2xl font-semibold font-figtree">
            {{ item.title }}
          </h3>
          <div
            class="border border-neutral-400 drop-shadow-lg hover:drop-shadow-md"
          >
            <figure class="relative w-full h-56">
              <img
                :src="item.thumbnail"
                alt=""
                class="object-cover w-full h-full"
                loading="lazy"
              />
              <span
                v-if="item.statusMessage?.length"
                class="absolute left-0 px-4 py-[5px] text-white uppercase top-6 font-figtree bg-snipe-blue"
              >
                {{
                  item.statusMessage[1] === "Custom Message"
                    ? item.statusCustom
                    : item.statusMessage[1]
                }}
              </span>
              <span
                v-if="item.price"
                class="absolute right-0 px-4 py-[5px] text-white uppercase top-6 font-figtree bg-bc-purple"
              >
                ${{ item.price }}
              </span>
              <button
                v-if="type === 'floorplans'"
                @click.stop.prevent="handleFavoriteClick(item)"
                :title="
                  item.isFavorite ? 'Remove from Favorites' : 'Add to Favorites'
                "
              >
                <HeartIconSolid
                  v-if="isLoggedIn && item.isFavorite"
                  :class="[
                    'absolute w-9 text-heart-red right-4',
                    item.price ? 'top-16' : 'top-4',
                  ]"
                />
                <HeartIconOutline
                  v-else
                  :class="[
                    'absolute w-9 text-heart-red  right-4 hover:fill-heart-red/75',
                    item.price ? 'top-16' : 'top-4',
                  ]"
                />
              </button>
            </figure>
            <div
              v-if="item.features"
              class="bg-[#f1f1f1] font-figtree text-lg italic font-light p-3 leading-none"
            >
              <span v-if="item.features?.length === 1">
                {{ item.features[0].feature }}
              </span>
              <span
                v-else
                v-for="feature in item.features"
                class="feature-list"
              >
                {{ feature.feature }}
              </span>
            </div>
            <div
              class="p-2 bg-white grid sm:px-6 sm:py-4 sm:gap-4 gap-1 empty:p-0"
              style="display: grid; grid-template-columns: repeat(2, 1fr)"
            >
              <div v-if="item.beds" class="flex">
                <i
                  class="content-center mr-2 text-2xl sm:text-4xl icon icon-beds text-fr-primary-dark"
                ></i>
                <div class="flex sm:flex-none">
                  <span
                    class="content-center mr-1 text-sm font-bold md:text-base"
                    >{{ item.beds }}</span
                  >
                  <span
                    class="md:text-base text-sm font-figtree content-center md:pt-0 pt-[2px]"
                    >BEDS</span
                  >
                </div>
              </div>
              <div v-if="item.baths" class="flex">
                <i
                  class="content-center mr-2 text-2xl sm:text-4xl icon icon-baths text-fr-primary-dark"
                ></i>
                <span
                  class="content-center mr-1 text-sm font-bold md:text-base"
                  >{{ item.baths }}</span
                >
                <span
                  class="md:text-base text-sm font-figtree content-center md:pt-0 pt-[2px]"
                  >BATH</span
                >
              </div>
              <div v-if="item.sqft" class="flex">
                <i
                  class="content-center mr-2 text-2xl sm:text-4xl icon icon-sq-ft-total text-fr-primary-dark"
                ></i>
                <span
                  class="content-center mr-1 text-sm font-bold md:text-base"
                  >{{ item.sqft }}</span
                >
                <span
                  class="md:text-base text-sm font-figtree content-center md:pt-0 pt-[2px]"
                  >SQ. FT.</span
                >
              </div>
              <div v-if="item.garage" class="flex">
                <i
                  class="content-center mr-2 text-2xl sm:text-4xl icon icon-garage text-fr-primary-dark"
                ></i>
                <span
                  class="content-center mr-1 text-sm font-bold md:text-base"
                  >{{ item.garage }}</span
                >
                <span
                  class="md:text-base text-sm font-figtree content-center md:pt-0 pt-[2px]"
                  >CAR GARAGE</span
                >
              </div>
            </div>
          </div>
        </NuxtLink>
      </ClientOnly>
    </div>
    <MoleculesFavoritesNotification />
  </div>
</template>

<style>
.product-grid {
  &.item-count-1,
  &.item-count-2 {
  }
  .feature-list {
    &::after {
      content: ", ";
    }
    &:last-of-type::after {
      @apply hidden;
    }
  }

  &.cursor-wait-override * {
    @apply !cursor-wait;
  }
}
</style>

<script setup>
import { HeartIcon as HeartIconSolid } from "@heroicons/vue/solid/index.js";
import { HeartIcon as HeartIconOutline } from "@heroicons/vue/outline/index.js";

import { storeToRefs } from "pinia";
import { onMounted } from "vue";
import { useRoute } from "vue-router";
const favoritesAuth = useFavoritesAuth();
const { isLoggedIn, user, userCookie } = storeToRefs(favoritesAuth);

const route = useRoute();

const hardRefresh = () => {
  window.location.reload();
};

const gridKey = ref(0);

watch(userCookie, (newValue, oldValue) => {
  if (newValue !== oldValue && route.name === "my-favorites") {
    refreshPageData();
    gridKey.value++;
  }
});

watch(
  () => route.fullPath,
  () => {
    gridKey.value++;
  },
);

const modalLogin = useModalLogin();
const { modalIsOpen } = storeToRefs(modalLogin);

const favorites = useFavorites();
const {
  favoritesSubmitting,
  notificationImageUrl,
  notificationIsOpen,
  notificationProductName,
  processingQueue,
} = storeToRefs(favorites);

favoritesAuth.refreshLoginStatus();

const refreshPageData = () => {
  refreshNuxtData();
  favoritesAuth.refreshLoginStatus();
};

const props = defineProps({
  organism: Array,
  type: String,
});

// Make a local mutable copy (so we can hide un-favorited plans on the
// favorites page)
const rawItems = ref([...props.organism]);

// Update rawItems whenever props.organism changes
watch(
  () => props.organism,
  (newValue) => {
    rawItems.value = [...newValue];
    // console.log("props.organism changed:", newValue);
  },
);

const fallbackImage = "/img/flintrock_fallback.jpg";

const gridItems = computed(() => {
  switch (props.type) {
    case "model-homes":
      return Array.isArray(props.organism)
        ? props.organism.map((item) => {
            return {
              id: item.databaseId,
              link: item.uri,
              title: item.title,
              thumbnail:
                getImageSrc(item.modelDetails?.elevations[0], "medium_large") ||
                fallbackImage,
              statusMessage: item.productStatus?.statusMessage,
              statusCustom: item.productStatus?.statusCustom,
              features:
                item.modelDetails?.floorplan?.floorplanDetails?.features,
              beds: detailMinMax(
                item.modelDetails?.floorplan?.floorplanDetails?.bedroomsMin,
                item.modelDetails?.floorplan?.floorplanDetails?.bedroomsMax,
              ),
              baths:
                item.modelDetails?.floorplan?.floorplanDetails?.bathroomsMin,
              sqft: detailMinMax(
                item.modelDetails?.floorplan?.floorplanDetails?.mainSqrFt,
                item.modelDetails?.floorplan?.floorplanDetails?.finishedSqrFt,
              ),
              garage: detailMinMax(
                item.modelDetails?.floorplan?.floorplanDetails?.garageMin,
                item.modelDetails?.floorplan?.floorplanDetails?.garage,
              ),
            };
          })
        : [];
    case "floorplans":
      const cookieFavorites = isValidJSON(userCookie.value?.favorites)
        ? JSON.parse(userCookie.value?.favorites)
        : false;

      return Array.isArray(rawItems.value)
        ? rawItems.value
            .map((item) => {
              if (!item?.floorplanDetails) return null;

              const location = item.floorplanDetails?.neighborhood
                ? item.floorplanDetails.neighborhood.map(
                    (n) => n.neighborhood?.city,
                  )
                : [];
              const isFavorite = favorites.isFavorite(
                item.databaseId,
                cookieFavorites,
              );
              if (route.name === "my-favorites" && !isFavorite) {
                return null;
              }
              return {
                id: item.databaseId,
                isFavorite: isFavorite,
                link: item.uri,
                title: item.floorplanDetails?.displayTitle ?? item.title,
                thumbnail:
                  getImageSrc(
                    item.floorplanDetails?.elevations[0],
                    "medium_large",
                  ) || fallbackImage,
                statusMessage: item.productStatus?.statusMessage,
                statusCustom: item.productStatus?.statusCustom,
                features: item.floorplanDetails?.features,
                beds: detailMinMax(
                  item.floorplanDetails?.bedroomsMin,
                  item.floorplanDetails?.bedroomsMax,
                ),
                baths: item.floorplanDetails?.bathroomsMin,
                sqft: detailMinMax(
                  item.floorplanDetails?.mainSqrFt,
                  item.floorplanDetails?.finishedSqrFt,
                ),
                garage: detailMinMax(
                  item.floorplanDetails?.garageMin,
                  item.floorplanDetails?.garage,
                ),
                basePrice: item.floorplanDetails?.basePrice ?? false,
                collection: item.floorplanDetails?.collection ?? "",
                type: item.floorplanDetails?.type,
                location: location,
              };
            })
            .filter((item) => item !== null)
            .sort((a, b) => {
              // Extract the first word from the title
              const wordA = a.title.split(/[\s+]/)[0].toLowerCase();
              const wordB = b.title.split(/[\s+]/)[0].toLowerCase();

              // Check if the titles of both items start with a word number
              if (numMap[wordA] !== undefined && numMap[wordB] !== undefined) {
                // Compare the number values
                return numMap[wordA] - numMap[wordB];
              } else if (numMap[wordA] !== undefined) {
                // If only the title of the first item starts with a word number,
                // consider it smaller (so it comes first in the sort order)
                return -1;
              } else if (numMap[wordB] !== undefined) {
                // If only the title of the second item starts with a word number,
                // consider it larger (so it comes later in the sort order)
                return 1;
              } else {
                // If neither title starts with a word number, compare the titles as strings
                return a.title.localeCompare(b.title);
              }
            })
        : [];
    case "quick-move-in":
      return Array.isArray(rawItems.value)
        ? rawItems.value.map((item) => {
            return {
              id: item.databaseId,
              link: item.uri,
              title: item.title,
              thumbnail:
                getImageSrc(
                  item.qmiHomeDetails?.elevations[0],
                  "medium_large",
                ) || fallbackImage,
              statusMessage: item.productStatus?.statusMessage,
              statusCustom: item.productStatus?.statusCustom,
              price: item.qmiHomeDetails?.price
                ? parseInt(item.qmiHomeDetails?.price).toLocaleString()
                : false,
              plan: item.qmiHomeDetails?.floorplan?.title,
              features:
                item.qmiHomeDetails?.floorplan?.floorplanDetails?.features,
              beds: item.qmiHomeDetails?.bedrooms,
              baths: item.qmiHomeDetails?.bathrooms,
              sqft: item.qmiHomeDetails?.finishedSqrFt.toLocaleString(),
              garage: detailMinMax(
                item.qmiHomeDetails?.garageMin,
                item.qmiHomeDetails?.garage,
              ),
            };
          })
        : [];
  }
});

const handleFavoriteClick = (item) => {
  if (!item) {
    console.error("No item provided to handleFavoriteClick");
    return;
  }

  // Save the scroll position
  const scrollY = window.scrollY;
  localStorage.setItem("savedScrollPosition", scrollY);

  if (isLoggedIn.value) {
    toggleFavorite(item);
  } else if (!modalIsOpen.value) {
    try {
      localStorage.setItem("preLoginFavoriteItem", JSON.stringify(item));
      modalLogin.openModal();
    } catch (err) {
      console.error("Error handling favorite click:", err);
    }
  }
};

const toggleFavorite = (item) => {
  const newFavoritesIds = favorites.toggleFavoriteProduct(item);

  rawItems.value = rawItems.value.filter((i) => {
    if (route.name === "my-favorites" && item.isFavorite) {
      return i.databaseId !== item.id;
    }
    return true;
  });
};

watch(modalIsOpen, (isOpen) => {
  let savedScrollPosition = localStorage.getItem("savedScrollPosition");

  // Restore the previous scroll position or fallback to section with id="see-homes" for Community pages
  const targetPosition =
    !isNaN(savedScrollPosition) && savedScrollPosition > 0
      ? savedScrollPosition
      : document.getElementById("see-homes")?.offsetTop || 0;

  if (targetPosition > 0) {
    window.scrollTo({ top: targetPosition, behavior: "instant" });
  }
});
</script>
